<template>
  <div>
    <b-alert variant="success" v-if="checkoutDataResponse.statusCode !== 200" show>
      <div class="alert-body">
        <span>{{checkoutDataResponse.message}}</span>
        <a v-if="checkoutDataResponse.statusCode === 1008" 
          @click="$router.push({name: 'login'})" class="lab-text-sunrise"> 
          Click Here For Login
        </a>
      </div>
    </b-alert>

    <div v-if="isLoadingPayment === false">
      <div class="checkout-card" v-if="screen === 'DESKTOP'">
        <div class="row confirm-g25-container">
          <div class="col-lg-12">
            <div class="confirm-card">
              <div class="nearly-done">
                <h4>Nearly Done</h4>
                <p>Select an ethnicity for each kit to assign a default region. Don’t worry, this won’t affect your results. </p>
              </div>
              <kits-ethnicity />
              <payment ref="payment"  :currentRouteData="currentRouteData"/>
              <check-box-confirm ref="checkboxConfirm" v-if="isUserLoggedIn === true"/>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <promotion-code/>
          <div class="step-buttons">
            <button class="lab-btn back" @click="prev()">Back</button>
            <button class="lab-btn next valid" @click="confirm()" :disabled="isStartLoading">
              <b-spinner v-if="$store.getters.getLoader || isStartLoading" small/>
              Confirm
            </button>
          </div>
        </div>

      </div>

      <div class="checkout-card-mobile" v-if="screen === 'MOBILE'">
        <div class="title">
          <h4>Nearly done....</h4>
        </div>
        <div class="desc">
          <p>Select an ethnicity for each kit to assign a default region. Don’t worry, this won’t affect your results. </p>
        </div>
        <div class="mb-2">
          <kits-ethnicity />
        </div>
        <div>
          <payment ref="payment"  :currentRouteData="currentRouteData"/>
        </div>
        <promotion-code/>
        <div>
          <check-box-confirm ref="checkboxConfirm" v-if="isUserLoggedIn === true"/>
        </div>
        <div class="step-buttons-mobile">
          <button class="lab-btn back" @click="prev()">Back</button>
          <button class="lab-btn next valid" @click="confirm()" :disabled="isStartLoading">
            <b-spinner v-if="$store.getters.getLoader || isStartLoading" small/>
            Confirm
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <b-alert variant="success" show>
        <div class="alert-body">
          <span>Waiting for payment response...  <b-spinner /></span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service';
import { DA_CHECKOUT_STEPS } from '@/common/config';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import KitsEthnicity from './KitsEthnicity.vue';
import Payment from '../../steps/Payment.vue';
import CheckBoxConfirm from './others/CheckBoxConfirm.vue';
import PromotionCode from '../../steps/PromotionCode.vue';

export default {
  components: {
    KitsEthnicity,
    Payment,
    CheckBoxConfirm,
    PromotionCode,
  },
  props: {
    currentRouteData: {
      type: Object,
    },
    screen: {
      type: String,
      default: 'DESKTOP',
    },
  },
  data() {
    return {
      DA_CHECKOUT_STEPS,
      isValid: true,

      checkoutDataResponse: { statusCode: 200 },
      isStartLoading: false,
      isLoadingPayment: false,
    }
  },
  methods: {
    checkOrderWarning(product, orderData, queryParams) {
      let errorMessage = null;
      orderData.kitList.forEach(fileItem => {
        if (product.productType === 1) {
          if (fileItem.rawFileKey === null || fileItem.rawFileKey === '') {
            errorMessage = 'Missing file upload! Please upload files of all kits.';
          }
        } 

        if (product.productType === 2) {
          if (fileItem.targetCoordinate === null || fileItem.targetCoordinate === '') {
            errorMessage = 'Missing past coordinate! Please past coordinates of all kits.';
          }
        }

        if (fileItem.presetEthnicityId === null) {
          fileItem.isEmptyPresetEthnicityId = true;
          this.isValid = false;
        }
      });

      if (errorMessage !== null) {
        AlertService.error(this, errorMessage);
        this.isValid = false;
      }

      if (orderData.isConfirmPrivacyPalicy === false) {
        this.isValid = false;
        this.$refs.checkboxConfirm.validateCheckbox();
      }
    },

    confirm(isPaymentSuccess = false) {
      /* eslint-disable */
      if(this.isStartLoading === true) {
        return;
      }
      this.isStartLoading = true;

      this.isValid = true;
      const product = this.productData.product;

      const orderData = this.$store.getters['deepACheckout/getCreateOrderData'];
      let queryParams = this.$store.getters['deepACheckout/getCheckoutQuery'];

      this.checkOrderWarning(product, orderData, queryParams);

      if (this.isValid != true) {
        this.isStartLoading = false;
        return;
      }

      if (isPaymentSuccess === false) {
        this.$refs.payment.handleSubmit((paymentResult) => {
          if (paymentResult.error) {
            AlertService.warning(this, paymentResult.error.message);
            this.isStartLoading = false;
            return;
          } else {
            if (paymentResult.paymentIntent.status !== 'succeeded') {
              this.isStartLoading = false;
              return;
            }
          }

          queryParams = this.$store.getters['deepACheckout/getCheckoutQuery'];

          this.saveCheckout({ createNewOrder: queryParams });
        });
      } else {
        queryParams = this.$store.getters['deepACheckout/getCheckoutQuery'];
        this.saveCheckout({ createNewOrder: queryParams });
      }
    },

    saveCheckout(queryParams) {
      this.$store.dispatch('deepACheckout/saveCheckout', queryParams)
        .then(dataResponse => {
          this.isStartLoading = false;
          this.isLoadingPayment = false;

          this.checkoutDataResponse = dataResponse;

          if (dataResponse.statusCode === 200) {
            this.$store.commit('deepACheckout/resetOrderState');
              this.$router.push({ name: 'dashboard' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Successful',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$t(dataResponse.message),
                    },
                  });
                  
                  localStorage.setItem('deepACheckout', null);
                  window.location.reload();
                });
          } else {
            AlertService.error(this, dataResponse.message);
          }
        });
    },

    handlePayment() {
      if (this.$router.currentRoute.query.payment_intent) {
        this.isLoadingPayment = true;
        ApiService.post('webhook/retrieve-payment-intent', { PaymentIntentId: this.$router.currentRoute.query.payment_intent }, this)
          .then(response => {
            if (response.statusCode === 200) {
              if (response.result.status !== 'succeeded') {
                AlertService.error(this, response.result.last_payment_error ? response.result.last_payment_error.message : 'Failed');
                this.isLoadingPayment = false;
              } else {
                this.confirm(true);
              }
            } else {
              AlertService.error(this, response.message);
              this.isLoadingPayment = false;
            }
          });
      }
    },

    prev() {
      this.$router.push(`/checkout/da/${this.DA_CHECKOUT_STEPS.UPLOAD}/${this.currentRouteData.productCode}`);
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
    isValidKits() {
      /* eslint-disable */
      const kits = this.$store.getters['deepACheckout/getCreateOrderData'].kits;

      return !kits.find(x => x.presetEthnicityId == null);
    },
    productData() {
      return this.$store.getters['deepACheckout/getProductData'];
    },
  },
  mounted() {
    this.handlePayment();
  },
}
</script>

<style lang="scss" scoped>
.checkout-card {
  max-width: 1100px;
}
.confirm-g25-container {
  padding: 1rem;
}

.checkout-card-mobile {
  .title {
    text-align: center;
  }
  .desc {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    p {
      font-size: 0.95rem;
      color: var(--lab-hydrargyrum);
    }
  }
}

.nearly-done {
  padding: 0.2rem 1.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    margin-top: 20px;
    line-height: 1rem;
    font-size: 0.7rem;
    color: var(--lab-hydrargyrum);
  }
}

.confirm-card {
  min-width: 450px
}
</style>
