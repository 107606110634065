<template>
  <div>
    <div class="checkout-card hidden-mobile" v-if="productData != null">
      <div class="upload-raw-container">
        <div class="d-flex justify-content-center align-items-center mr-3">
          <upload-raw-icon />
        </div>
        <div class="upload-raw-body">
          <div class="title">
            <h4>Upload your file(s)</h4>
          </div>

          <div class="desc">
            <p>Upload your <b>autosomal</b> Raw DNA Data file from DNA Company</p>
          </div>

          <div class="select-quantity">
            <h6>How many files do you want to upload?</h6>
            <quantity-dropdown />
            <p>
              Upload your <b>autosomal</b> Raw DNA files for each field below and then give them a recognazible name i.e. ‘Mother’s DNA’
            </p>
          </div>

          <div class="kits mb-4">
            <div class="kit-item mb-2" 
              v-for="(kitItem, index) in orderKits"
              :key="index">

              <h6>{{index + 1}}.Kit</h6>
              <div class="kit-item-body">
                <div class="d-flex justify-content-start align-items-center">
                  <input type="text" class="form-control kit-name mr-1" v-model="kitItem.kitName"
                    placeholder="Kit name"
                    :disabled="kitItem.rawFileKey === null"
                    :class="{ 
                      'dashed-input': kitItem.emptyKitName,
                      'filled-input': kitItem.kitName.length >= 1,
                    }"/>

                  <button class="lab-btn past-coordinates" @click="openUpload(kitItem)" :disabled="kitItem.isLoading || kitItem.rawFileName !== ''"> 
                    <upload-icon /> Upload File 
                  </button>
                  
                  <span class="status ml-1" v-show="kitItem.rawFileName === ''"> 
                    <b-spinner  v-if="kitItem.isLoading" class="mr-1" small />
                    <!-- 0 % -->
                  </span>
                  <span class="status ml-1" v-show="kitItem.rawFileName !== ''"> 
                    <checked-icon /> Completed
                  </span>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                  <span class="delete-kit" @click="deleteFile(kitItem)"  v-show="kitItem.rawFileName !== ''">
                    Delete
                  </span>
                </div>
              </div>
              <small class="text-danger" v-show="kitItem.emptyRawFileKey === true">
                You must upload raw data file
              </small>
              <small class="text-success" v-show="kitItem.rawFileName !== ''">
                {{kitItem.rawFileName}}
              </small>
            </div>
          </div>

          <div class="warn-cost">
            <p>Having a problem uploading your File? <a href="https://illustrativedna.com/contact/" target="_blank">Contact support</a> </p>
            <hr />
            <div class="cost">Cost: <span>{{ productData != null? productData.product.currencyUnit : ''}}{{subtotal}} </span></div>
          </div>
        </div>
      </div>
      <div class="step-buttons">
        <button class="lab-btn back" @click="prev()"><feather-icon icon="ArrowLeftIcon" size="20"/> Back To Dashboard</button>
        <button class="lab-btn next valid" @click="submit()">
          Next
        </button>
      </div>
    </div>

    <div class="checkout-card-mobile hidden-desktop" v-if="productData != null">
      <div class="upload-raw-container">
        <div class="title">
          <h4>Upload your file(s)</h4>
        </div>
        <div class="upload-raw-body">
          <div class="select-quantity">
            <h6>How many files do you want to upload?</h6>
            <div class="d-flex justify-content-center mt-1 mb-1">
              <quantity-dropdown />
            </div>
            <p>
              Upload your <b>autosomal</b> Raw DNA files for each field below and then give them a recognazible name i.e. ‘Mother’s DNA’
            </p>
          </div>

          <div class="kits mb-4 mobile-body">
            <div class="kit-item mb-2" 
              v-for="(kitItem, index) in orderKits"
              :key="index">

              <h6>{{index + 1}}.Kit</h6>
              <div class="kit-item-body">
                <div class="d-flex justify-content-between align-items-center">
                  <input type="text" class="form-control kit-name mr-1" v-model="kitItem.kitName"
                    placeholder="Kit name"
                    :disabled="kitItem.rawFileKey === null"
                    :class="{ 
                      'dashed-input': kitItem.emptyKitName,
                      'filled-input': kitItem.kitName.length >= 1,
                    }"/>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <button class="lab-btn past-coordinates" v-show="kitItem.rawFileName === ''" @click="openUpload(kitItem)"> <upload-icon /></button>
                  
                  <span class="status ml-1"  v-if="kitItem.isLoading"> 
                    <b-spinner class="mr-1" small />
                  </span>
                  <span class="status ml-1" v-show="kitItem.rawFileName !== ''"> 
                    <checked-icon />
                  </span>
                  
                  <span class="delete-kit" v-show="kitItem.rawFileName !== ''">
                    <feather-icon
                      @click="deleteFile(kitItem)"
                      icon="Trash2Icon"
                      class="cursor-pointer white"
                      size="16"
                    />
                  </span>
                </div>
              </div>
              <small class="text-danger" v-show="kitItem.emptyRawFileKey === true">
                You must upload raw data file
              </small>
              <small class="text-success" v-show="kitItem.rawFileName !== ''">
                {{kitItem.rawFileName}}
              </small>
            </div>
          </div>
        </div>

        <div class="warn-cost">
          <p>Having a problem uploading your File? <a href="https://illustrativedna.com/contact/" target="_blank">Contact support</a> </p>
          <hr />
          <div class="cost">Cost: <span>{{ productData != null? productData.product.currencyUnit : ''}}{{subtotal}} </span></div>
        </div>

        <div class="step-buttons-mobile">
          <button class="lab-btn back" @click="prev()"><feather-icon icon="ArrowLeftIcon" size="20"/> Back</button>
          <button class="lab-btn next valid" @click="submit()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service';
import useJwt from '@/auth/jwt/useJwt'

import { PRODUCT_CODES, DA_CHECKOUT_STEPS } from '@/common/config';
import UploadRawIcon from '@/layouts/icons/store/UploadRawIcon.vue';
import CheckedIcon from '@/layouts/icons/CheckedIcon.vue';
import UploadIcon from '@/layouts/icons/UploadIcon.vue';
import QuantityDropdown from './others/QuantityDropdown.vue';

export default {
  components: {
    CheckedIcon,
    UploadIcon,
    UploadRawIcon,
    QuantityDropdown,
  },
  props: {
    screen: {
      type: String,
      default: 'DESKTOP',
    },
  },
  data() {
    return {
      PRODUCT_CODES,
      DA_CHECKOUT_STEPS,
    }
  },
  methods: {
    submit() {
      /* eslint-disable */
      const kitsData = this.$store.getters['deepACheckout/getCreateOrderData'].kitList;

      let isEmptyRawFileKey = false;
      kitsData.forEach(item => {
        if (item.rawFileKey === null) {
          isEmptyRawFileKey = true;
          item.emptyRawFileKey = true;
        } else {
          item.emptyRawFileKey = false;
        }
      });

      if (isEmptyRawFileKey) {
        return;
      }
      
      let isemptyKitName = false;
      kitsData.forEach(item => {
        if (item.kitName === '') {
          isemptyKitName = true;
          item.emptyKitName = true;
        } else {
          item.emptyKitName = false;
        }
      });

      if (isemptyKitName) {
        return;
      }

      this.next();
    },

    openUpload(fileItem) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        /* eslint-disable*/
        const file = event.target.files[0];

        const fileSizeInMB = file.size / (1024 * 1024); 
        if(fileSizeInMB <= 1) {
          AlertService.warning(this, 'Invalid file size...! File size must be larger than (1MB)');
          return 0;
        }
        
        fileItem.isLoading = true;

        const fileKey = this.generateFileKey();
        ApiService.get(`RawDataFile/presigned-url?fileKey=${fileKey}&contentType=${file.type}`, {}, this)
          .then(response => {
            if (response.statusCode === 200) {
              const presignedUrl = response.result;
              useJwt.axiosIns.put(presignedUrl, file, { headers: { 'Content-Type': file.type } })
                .then(uploadFileResponse => {
                  this.$store.commit('deepACheckout/setKitFileData', {
                    index: fileItem.id,
                    rawFileKey: fileKey, 
                    rawFileName: file.name, 
                    rawFileFormat: file.type
                  });
                  fileItem.isLoading = false;
                });
            } else {
              fileItem.isLoading = false;
              AlertService.warning(this, this.$t(response.message));
            }
          });
      })
      fileInput.click();
    },

    deleteFile(fileItem) {
      fileItem.isLoading = true;
      ApiService.get(`RawDataFile/delete?fileKey=${fileItem.rawFileKey}`, {}, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('deepACheckout/removeKitFileData', {
                index: fileItem.id,
              });
            } else {
              AlertService.warning(this, this.$t(response.message));
            }
            fileItem.isLoading = false;
          });
    },
    
    generateFileKey() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },
    
    next() {
      if (this.isUserLoggedIn) {
        this.$router.push(`/checkout/da/${DA_CHECKOUT_STEPS.CONFIRM}/${PRODUCT_CODES.UPLOAD_DA_RAW_DATA}`);
      } else {
        this.$router.push(`/checkout/da/${DA_CHECKOUT_STEPS.ACCOUNT}/${PRODUCT_CODES.UPLOAD_DA_RAW_DATA}`);
      }
    },
    prev() {
      this.$router.push('/dashboard');
    },
  },
  computed: {
    productData() {
      return this.$store.getters['deepACheckout/getProductData'];
    },
    orderKits() {
      return this.$store.getters['deepACheckout/getCreateOrderData'].kitList;
    },
    subtotal() {
      return this.$store.getters['deepACheckout/getCreateOrderData'].subtotal;
    },    
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
}
</script>

<style lang="scss" scoped>

.checkout-card .upload-raw-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 90%;

  .upload-raw-body {
    position: relative;
    max-width: 450px;
  }

  .title {
    margin-top: 10px;
    h4 {
      font-size: 1.6rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
    }
  }
  .desc {
    margin-top: 20px;
    p {
      line-height: 1rem;
      font-size: 0.7rem;
      color: var(--lab-hydrargyrum);
    }
  }

  .select-quantity {
    h6 {
      font-size: 0.7rem;
      font-weight: 600;
    }
    p {
      margin-top: 20px;
      line-height: 1rem;
      font-size: 0.7rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
    }
  }
}

.checkout-card-mobile .upload-raw-container {
  display: block;
  padding: 0 2rem;

  .mobile-body {
    min-height: 220px;
  }
  .title {
    text-align: center;
    margin: 1rem 0;
  }
  .select-quantity {
    h6 {
      font-size: 0.8rem;
      margin: 1rem 0;
    }
    p {
      font-size: 0.8rem;
      color: var(--lab-hydrargyrum);
    }
  }
  .warn-cost {
    p {
      font-size: 0.8rem;
      line-height: 1.2rem;
      color: var(--lab-carbon-fiber);
    }
  }

  .delete-kit {
    background-color: #E88577;
    padding: 5px;
    .white {
      color: var(--lab-white);
      font-weight: bold;
    }
  }

  .kit-item {
    justify-content: space-between;
  }
  .kit-name {
    width: 150px;
  }
}

.form-control {
  border: 2px solid var(--lab-light-house) !important;
  background-color: var(--lab-white) !important;
  color: #595959 !important;
}
.form-control:disabled {
  background-color: var(--lab-light-house) !important;
}
.form-control:not(:empty) {
  background-color: var(--lab-white) !important;
}
.form-control:focus {
  background-color: var(--lab-white) !important;
}

</style>
